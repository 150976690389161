var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',{attrs:{"title":"Edit Hospital"}},[_c('b-row',{staticClass:"d-flex justify-content-center"},[_c('b-col',{attrs:{"cols":"8"}},[_c('validation-observer',{ref:"hospitalRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"name","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","placeholder":"The name of hospital","state":errors.length > 0 ? false : null},model:{value:(_vm.hospital.name),callback:function ($$v) {_vm.$set(_vm.hospital, "name", $$v)},expression:"hospital.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"hospital ID","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Hospital ID","label-for":"hospital_id","description":"Some simple ID for the hospital. Choose to your liking.","label-cols-md":"4"}},[_c('b-form-input',{attrs:{"id":"hospital_id","placeholder":"Hospital ID","state":errors.length > 0 ? false : null},model:{value:(_vm.hospital.hospital_id),callback:function ($$v) {_vm.$set(_vm.hospital, "hospital_id", $$v)},expression:"hospital.hospital_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"contact email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Contact email","label-for":"contact_email","description":"Who is the main/one of the contact person(s)?","label-cols-md":"4"}},[_c('b-form-input',{attrs:{"id":"contact_email","type":"email","placeholder":"Contact email","state":errors.length > 0 ? false : null},model:{value:(_vm.hospital.contact_email),callback:function ($$v) {_vm.$set(_vm.hospital, "contact_email", $$v)},expression:"hospital.contact_email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Contact Info","label-for":"contact_info","description":"Is there further information for contacting the hospital?","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"contact info","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"contact_info","placeholder":"Contact Info","state":errors.length > 0 ? false : null},model:{value:(_vm.hospital.contact_info),callback:function ($$v) {_vm.$set(_vm.hospital, "contact_info", $$v)},expression:"hospital.contact_info"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Notes","label-for":"notes","description":"Any additional notes/information?","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"notes","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"notes","placeholder":"Notes","state":errors.length > 0 ? false : null},model:{value:(_vm.hospital.notes),callback:function ($$v) {_vm.$set(_vm.hospital, "notes", $$v)},expression:"hospital.notes"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"offset-md":"4"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"secondary"},on:{"click":function($event){return _vm.$router.push({ name: 'admin-hospitals' })}}},[_vm._v(" Back ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"reset","variant":"outline-secondary"}},[_vm._v(" Reset ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.handleUpdate.apply(null, arguments)}}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }